/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */

import React from 'react';

import Button from '../elements/Button';
import Pic from '../assets/images/LOGO.jpg';

export default function BrandIcon() {
  return (
    <Button
      className=""
      type="link"
      href="/"
    >
      <img src={Pic} alt="Betech Systems" width={70} />
    </Button>
  );
}
